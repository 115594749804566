import styles from "./Footer.module.css";
import Logo from "../images/LWStransparent.png";

const Footer = () => {
  const dateYear = new Date().getFullYear();

  return (
    <div className={styles.section}>
      <div className={styles["email-section"]}>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="#ccc"
            className={styles["small-icon"]}
          >
            <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
            <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
          </svg>
        </span>
        <a className={styles.email} href="mailto::info@leadingwaysolutions.com">
          info@leadingwaysolutions.com
        </a>
      </div>
      <div className={styles["phone-section"]}>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#ccc"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#ccc"
            className={styles["small-icon"]}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
            />
          </svg>
        </span>

        <a className={styles.phone} href="tel:17179648929">
          717-964-8929
        </a>
      </div>

      <div className={styles["footer-logo"]}>
        <img className={styles["small-logo"]} src={Logo} alt="LWS-logo"></img>
      </div>
      {dateYear == "2023" ? (
        <p>&#169; 2023 Leading Way Solutions LLC</p>
      ) : (
        <p> &#169; 2023 - {dateYear} Leading Way Solutions LLC</p>
      )}
    </div>
  );
};

export default Footer;
