import { initializeApp } from "firebase/app";

import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBazzXgtvWFJIk1hGZ1wkkRbn8RFuCxnqI",
  authDomain: "lws-public-website.firebaseapp.com",
  projectId: "lws-public-website",
  storageBucket: "lws-public-website.appspot.com",
  messagingSenderId: "590757258840",
  appId: "1:590757258840:web:e3c97b07df6a62a1318c2d",
  measurementId: "G-FLRRE5CEYC",
};

// init firebase
const app = initializeApp(firebaseConfig);

// init firebase functions
const cloudFuncs = getFunctions(app);

export { cloudFuncs };
