import ContactButton from "../UI/ContactButton";
import styles from "./About.module.css";

const About = () => {
  const buttonPermanent = false;
  return (
    <div className={styles["back-color"]}>
      <div className={styles["section-about"]}>
        <p>
          With more than thirty years of combined experience in software
          development and consulting, Leading Way Solutions can help you reach
          your goals. We can help solve problems, both large and small. It
          doesn't matter if you have an established business or are just
          starting out; we are excited to find a way to keep you moving in the
          right direction.
        </p>
        <ContactButton buttonPermanent={buttonPermanent} />
      </div>
    </div>
  );
};

export default About;
