import CloudIcon from "@heroicons/react/24/outline/CloudIcon";
import LightBulbIcon from "@heroicons/react/24/outline/LightBulbIcon";
import DevicePhoneMobileIcon from "@heroicons/react/24/outline/DevicePhoneMobileIcon";
import ComputerDesktopIcon from "@heroicons/react/24/outline/ComputerDesktopIcon";
import PuzzlePieceIcon from "@heroicons/react/24/outline/PuzzlePieceIcon";
import ChatBubbleLeftIcon from "@heroicons/react/24/outline/ChatBubbleLeftIcon";
import styles from "./ServiceOffered.module.css";
const ServiceOffered = () => {
  return (
    <section className={styles["serv-section"]}>
      <div className={styles["container"]}>
        <div className={styles["service-title"]}>
          Services Offered by Leading Way Solutions
        </div>
        <div className={styles["services-list"]}>
          <ul>

          <li>
              <div className={styles["service-row"]}>
                <DevicePhoneMobileIcon
                  className={styles.icon}
                ></DevicePhoneMobileIcon>
                <div className={styles.description}>
                  <p>Android and iOS mobile app development</p>
                </div>
              </div>
            </li>

            <li>
              <div className={styles["service-row"]}>
                <ComputerDesktopIcon
                  className={styles.icon}
                ></ComputerDesktopIcon>
                <div className={styles.description}>
                  <p>Web site creation and consulting</p>
                </div>
              </div>
            </li>

            <li>
              <div className={styles["service-row"]}>
                <CloudIcon className={styles.icon}></CloudIcon>
                <div className={styles.description}>
                  <p>Cloud-based solutions and consulting</p>
                </div>
              </div>
            </li>
          

            <li>
              <div className={styles["service-row"]}>
                <LightBulbIcon className={styles.icon}></LightBulbIcon>
                <div className={styles.description}>
                  <p>Idea analysis and refinement</p>
                </div>
              </div>
            </li>

            <li>
              <div className={styles["service-row"]}>
                <ChatBubbleLeftIcon
                  className={styles.icon}
                ></ChatBubbleLeftIcon>
                <div className={styles.description}>
                  <p>Mobile app consulting</p>
                </div>
              </div>
            </li>

            <li>
              <div className={styles["service-row"]}>
                <PuzzlePieceIcon className={styles.icon}></PuzzlePieceIcon>
                <div className={styles.description}>
                  <p>Solution architecture</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ServiceOffered;
