import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { cloudFuncs } from "../firebase/config";
import { httpsCallable } from "firebase/functions";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "./ContactForm.module.css";

const ContactForm = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(false);
  const [finish, setFinish] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setErrorMsg("");

    if (!name) {
      setErrorMsg("You must enter your name to contact us.");
      setError(true);
      return;
    }
    if (message.length < 1) {
      setErrorMsg("You must enter a message to contact us.");
      setError(true);
      return;
    }
    if (!phoneNumber && !email) {
      setErrorMsg(
        "You must enter an email address or a phone number to contact us."
      );
      setError(true);
      return;
    }

    const fn = httpsCallable(cloudFuncs, "sendContactUsMessage");
    const response = await fn({ name, phoneNumber, email, message });
    setFinish(true);
  };
  const cancelClick = () => {
    setError(false);
    setFinish(false);
    navigate("/");
  };

  return (
    <>
      <Header />
      <div className={styles["contact-container"]}>
        <div className={styles["contact-form"]}>
          <h1>Contact Leading Way Solutions</h1>
          <p>
            Please enter your name, either a phone number or email address, and
            a brief message. We will respond to your inquiry as quickly as we
            can!
          </p>
          <form onSubmit={handleSubmit}>
            <label className={styles["contact-label"]}>
              <span>Name:</span>
              <input
                className={styles["contact-input"]}
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </label>
            <label className={styles["contact-label"]}>
              <span>Phone number:</span>
              <input
                className={styles["contact-input"]}
                type="text"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
              />
            </label>
            <label className={styles["contact-label"]}>
              <span>Email:</span>
              <input
                className={styles["contact-input"]}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </label>
            <label className={styles["contact-label"]}>
              <span>Message:</span>
              <textarea
                className={styles["contact-input"]}
                rows="4"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
            </label>
            {error && (
              <div className={styles.error}>
                <p>{errorMsg}</p>
              </div>
            )}
            {!finish && (
              <div className={styles["button-section"]}>
                <button className={styles["message-button"]}>
                  Send Message
                </button>
                <button
                  className={styles["cancel-button"]}
                  onClick={cancelClick}
                >
                  Cancel
                </button>
              </div>
            )}
            {finish && !error && (
              <div className={styles["finish-email"]}>
                <h1>
                  Thank you for reaching out to us at Leading Way Solutions. We
                  will be in touch soon!
                </h1>
                <div className={styles["button-section"]}>
                  <button
                    className={styles["finish-button"]}
                    onClick={cancelClick}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ContactForm;
