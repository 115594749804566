import styles from "./Header.module.css";
import Logo from "../images/LWStransparent.png";

const Header = () => {
  return (
    <div className={styles.backColor}>
      <div className={styles.header}>
        <div className={styles.container}>
          <img className={styles.logoImg} src={Logo} alt="LWS-logo"/>
          <div className={styles.titles}>
            <div className={styles.title}>Leading Way Solutions</div>
            <div className={styles.logoTag}>illuminating your future</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
