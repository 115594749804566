import { useState, useEffect } from "react";
import styles from "./Slider.module.css";
import Card from "./Card";
import Circles from "./Circles";

const Slider = ({ portfolioItems }) => {

  const [itemIndex, setItemIndex] = useState(0);
  const [circleArray, setCircleArray] = useState([{index:0, selected:true}, {index:1, selected:false},{index:2, selected:false} ])

  const getItem = () =>{
    return (portfolioItems[itemIndex]);
  };

  const arrowRightClick = () =>{
    console.log('line 15', itemIndex);
    let index = itemIndex;
    const totalNum = portfolioItems.length;
    if (index === (totalNum - 1)){
      index = 0;
      console.log('line 21',index);
      setItemIndex(index);
    }
    else{
      index++;
      console.log('line 26',index);
      setItemIndex(index);
    };

    let tempArray = [];
    for (const i of circleArray){
      if (i.index === index){
        tempArray.push({index: i.index, selected:true });
      }
      else{
        tempArray.push({index: i.index, selected:false });
      };
    };
    console.log('line 25', tempArray);
    setCircleArray(tempArray);

  };

  const arrowLeftClick = () =>{
    console.log('line 32', itemIndex);
    let index = itemIndex;
    const totalNum = portfolioItems.length;
    if (index === (0)){
      index = totalNum - 1;
      console.log('line 37',index);
      setItemIndex(index);
    }
    else{
      index--;
      console.log('line42',index);
      setItemIndex(index);
    };

    let tempArray = [];
    for (const i of circleArray){
      if (i.index === index){
        tempArray.push({index: i.index, selected:true });
      }
      else{
        tempArray.push({index: i.index, selected:false });
      };
    };
    console.log('line 25', tempArray);
    setCircleArray(tempArray);

  }

  const onCircleClick = (index) =>{
    console.log('line 13', index);
    setItemIndex(index);
    let tempArray = [];
    for (const i of circleArray){
      if (i.index === index){
        tempArray.push({index: i.index, selected:true });
      }
      else{
        tempArray.push({index: i.index, selected:false });
      };
    };
    console.log('line 25', tempArray);
    setCircleArray(tempArray);
  };

  return (
    <div>
      <div className={styles.card}>
          <div className={styles.row}>
            <p className={styles.leftArrow} onClick={arrowLeftClick}>&#x276E;</p>
            <Card  portfolioItem={getItem()}/>
            <p className={styles.rightArrow} onClick={arrowRightClick}>&#x276F;</p>
          </div>
          <Circles circleArray={circleArray} onClick={onCircleClick}/>
          
      </div>
    </div>
  );
};
export default Slider;
