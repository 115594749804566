import Slider from "../UI/Slider";
import styles from "./Portfolio.module.css";
import Img1 from "../images/rms-2.0-screenshot.webp";
import Img2 from "../images/giraffeg4.webp";
import Img3 from "../images/bardeum-screenshot.webp";

const Portfolio = () => {
  const portfolioItems = [
    {
      image: Img1,
      title: "Retail Mastery System 2.0",
      urlLink: "https://play.google.com/store/apps/details?id=com.whizbangtraining.rms.android",
      description:
      "The Retail Mastery System 2.0 is the easiest, fastest, and best way to grasp the 11 Critical Retail Skills necessary to run a retail business.",
    },
    {
      image: Img2,
      title: "Giraffe G4",
      urlLink:"https://play.google.com/store/apps/details?id=com.giraffe.g4userapp",
      description: "GiraffeG4 Sentinel User App will warn the operator of a tall vehicle if they are approaching a 'Low Clearance' obstacle.",
    },
    {
      image: Img3,
      title: "Bardeum",
      urlLink:
        "https://play.google.com/store/apps/details?id=com.museumedutainment.android",
      description: "Don't Just See the Sites; Step Inside their Stories!",
    },
  ];
  return (
    <div className={styles["pf-section"]}>
      <h2>Portfolio</h2>
      <Slider portfolioItems={portfolioItems}/>
    </div>
  );
};
export default Portfolio;
