import { Link } from "react-router-dom";
import styles from "./ContactButton.module.css";

const ContactButton = ({ buttonPermanent }) => {
  return (
    <div>
      {!buttonPermanent && (
        <Link to={"/contact"} className={styles["button-class"]}>
          Contact Us
        </Link>
      )}
      {buttonPermanent && (
        <Link to={"/contact"} className={styles["button-class-permanent"]}>
          Contact Us
        </Link>
      )}
    </div>
  );
};

export default ContactButton;
