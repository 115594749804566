import { useEffect, useState } from "react";
import styles from "./Circles.module.css";

const Circles = ({onClick, circleArray}) => {
    console.log('line 5', circleArray);

    return(
        <div>
            {circleArray.map((i)=>(
                <div onClick={()=>{onClick(i.index)}} className={styles.circleRow} key={i.index}><div className={`${styles.circle} ${i.selected && styles.selectedCircle}` }></div></div>
            ))}
        </div>
    );
};
export default Circles;