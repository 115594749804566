import styles from "./Testimonials.module.css";

const Testimonials = () => {
  return (
    <section>
      <div className={styles["testimonials-section"]}>
        <div className={styles["container"]}>
          <h2>Testimonials</h2>

          <div className={styles.row}>
            {/* testimonial one */}
            <div>
              <p>
                "A wealth of knowledge on mobile app development that was shared
                with me in a clear and articulate manner. Simply an amazing
                resource, I would not hesitate to hire again, and will do so in
                the future."
              </p>
            </div>

            {/* testimonial two */}
            <div>
              <p>
                "Awesome to work with. Understood what we needed and was able to
                adjust the deliverable to exceed our expectations. The app
                worked great and was well written."
              </p>
            </div>

            {/* testimonial three */}
            <div>
              <p>
                "Did an excellent job taking the rough idea and workflows and
                outline the multiple touch points to develop, support, and
                update the app concept. This has aided me in better
                understanding my idea and has helped land a meeting with
                possible partners."
              </p>
            </div>

            {/* testimonial four */}
            <div>
              <p>"Very communicative, on-time, detail-oriented."</p>
            </div>

            {/* testimonial five */}
            <div>
              <p>
                "Terrific to collaborate with. Interactions were always pleasant
                and communication was superb."
              </p>
            </div>

            {/* testimonial six */}
            <div>
              <p>
                "Great to work with. Excellent communication. I wouldn't even
                consider hiring anyone else."
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Testimonials;
